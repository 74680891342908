<template>
    <div class="course">
        <div class="top-head">
            <!-- <div class="return" @click="$router.go(-1)"><img src="@/assets/images/return.png"></div> -->
            <div class="text-center">发现</div>
            <div class="edit" @click="exit">退出</div>
        </div>
        <ul class="type-list clearfix">
            <li v-for="item in typeList" class="ell" @click="$router.push({path:'/course/find/list',query:{uid:uid,from:from,id:item.id,name:item.type_name}})">
                <img :src="domainUrl+item.img" alt=""><br>
                {{item.type_name}}
            </li>
        </ul>
        <div class="find-title">课程抢鲜</div>
        <ul class="course-list">
            <li v-for="item in courseData" @click="$router.push({path:'/course/find/detail',query:{id:item.id}})">
                <img :src="domainUrl+item.photo" alt="">
                <div class="info">
                    <div class="title ell">{{item.class_name}}</div>
                    <div class="text ell">
                        <span>{{item.count}}讲</span>
                        {{item.feature}}
                    </div>
                    <div class="detail">
                        <span class="fr extend" v-on:click.stop="share(item)">推广<img src="@/assets/images/course/extend.png" alt=""></span>
                        <em class="free" v-if="item.class_type==2">免费</em>
                        <em class="buy" v-else-if="item.buy==0">付费</em>
                        <em class="bought" v-else>付费</em>
                        <div class="price" v-if="item.buy==1&&item.class_type!=2">已购买</div>
                        <div class="price" v-else-if="item.class_type!=2">¥{{item.money}}</div>
                        <div class="num" v-if="item.class_type!=2">{{item.order}}人购买</div>
                    </div>
                </div>
            </li>
        </ul>
        <!-- 推广 -->
        <share v-if="shareShow" :data="data" @close="shareShow=false"></share>
    </div>
</template>
<script>
import Vue from "vue";
import share from "../common/share";
export default {
    components: { share },
    data() {
        return {
            shareShow: false,
            data: {},
            typeList: [],
            courseData: [],
            uid: this.$route.query.uid ? this.$route.query.uid : Vue.getUser().uid,
            from: this.$route.query.from ? this.$route.query.from : Vue.getUser().from,  //1用户端 2阿姨端
            name: this.$route.query.name ? this.$route.query.name : Vue.getUser().name
        }
    },
    created() {
        if (!!this.$route.query.uid) {
            Vue.setData("CourseInfo", { uid: this.uid, from: this.from, name: this.name });
        }
        this.getList();
    },
    mounted() {

    },
    methods: {
        share(item) {
            if (this.from == 2) {
                this.data = item;
                this.shareShow = true
            } else if (this.from == 1) {
                var u = navigator.userAgent;
                var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
                var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
                if (isiOS) {
                    window.webkit.messageHandlers.Share.postMessage({ "nickName": this.name, "courseName": item.class_name, "courseImage": this.domainUrl + item.photo, "courseDescription": item.share_desc ? item.share_desc : item.feature, "sharedQRCodeUrl": "http://school.hailuoguniang.com/#/course/login?parent_id=" + item.id, });
                } else if (isAndroid) {
                    SharedUtils.showShared(this.name, item.class_name, this.domainUrl + item.photo, item.share_desc ? item.share_desc : item.feature, "http://school.hailuoguniang.com/#/course/login?parent_id=" + item.id);
                }
            }

        },
        exit() {
            if (this.from == 2) {
                window.location.href = 'http://aunt.hailuoguniang.com/#/home'
            } else if (this.from == 1) {
                var u = navigator.userAgent;
                var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
                var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
                if (isiOS) {
                    window.webkit.messageHandlers.Exit.postMessage(null);
                } else if (isAndroid) {
                    SharedUtils.exitPage();
                }
            }
        },
        getList() {
            this.$ajax.send({
                url: "/course/index",
                data: { uid: Vue.getUser().uid },
                type: "post",
                success: data => {
                    if (!!data && data.code == "1001") {
                        this.typeList = data.data.type;
                        this.courseData = data.data.hot_class;
                        this.data=data.data.hot_class[0]
                    }
                }
            });
        },
    }
}
</script>
<style lang="scss" scoped>
.type-list {
    padding: 104px 10px 10px 10px;
    li {
        width: 20%;
        text-align: center;
        float: left;
        margin: 16px 0;
        font-size: 26px;
        img {
            width: 90px;
            height: 90px;
        }
    }
}
.find-title {
    margin: 20px 32px 0 32px;
    font-size: 32px;
    line-height: 45px;
    padding-bottom: 13px;
    border-bottom: 1px solid #f1f2f3;
}
.extend {
    vertical-align: middle;
    img {
        width: 30px;
        height: 30px;
        border: none;
        vertical-align: middle;
        margin-left: 3px;
    }
}
.course-list {
    padding-bottom: 98px;
}
</style>